
.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.mapContainer{
  margin: 0;
  padding: 0;
  flex: 0.4;
}
.navbar{
z-index: 100;
position: absolute;
top: 5vh;
color: #fff;
padding-left: 1rem;
padding-right: 1rem;
padding-top: 1rem;
padding-bottom: 1rem;
background: rgba(238,174,202,0.8);
background: radial-gradient(circle, rgba(238,174,202,0.8) 0%, rgba(148,187,233,0.8) 100%); 
border-radius:1rem;
margin-left: 2rem;
display: grid;
place-items: center;
font-size: 1.25rem;
}
.register{
 flex:0.6;
}
.centerButton{
  z-index: 100;
position: absolute;
padding:1rem;
display: grid;
place-items:center;
top: 30vh;
color: #fff;
background: rgba(238,174,202,0.8);
background: radial-gradient(circle, rgba(238,174,202,0.8) 0%, rgba(148,187,233,0.8) 100%); 
border-radius:1rem;
margin-left: 2rem;
font-size: 1.25rem;

}