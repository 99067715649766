@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: "Poppins", monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 0.5rem;
  scrollbar-color: #e2bed7 #fff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #e2bed7;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 10px;
  border: 3px solid #e2bed7;
}
